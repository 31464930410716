import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { addListingToFavorites, removeListingFromFavorites } from '../../ducks/user.duck';
import { SearchMap, ModalInMobile, LayoutWrapperFooter, Footer, Page } from '../../components';
import { TopbarContainer } from '../../containers';

import { searchMapListings, setActiveListing, sendEnquiry } from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.module.css';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfig),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  render() {
    const {
      intl,
      currentUser,
      listings,
      filterConfig,
      sortConfig,
      history,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      sendEnquiryInProgress,
      sendEnquirySuccess,
      sendEnquiryError,
      onSendEnquiry,
      addToFavorites,
      removeFromFavorites,
      params = {},
    } = this.props;
    const jobRole = params['jobRole'];
    const jobRoleOptions = config.custom.jobRoles;
    const jobRoleOption =
      jobRole ? jobRoleOptions.find(option => option.key === jobRole) : null;
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { address, bounds, origin } = searchInURL || {};
    let { title, description, schema } = createSearchResultSchema(listings, address, intl);

    if (jobRoleOption) {
      title = `${jobRoleOption.label} Mentors | Career Navig8r`;
      description = `Career Navig8r have ${jobRoleOption.label} mentors to help guide you through and progress in your career. Find a mentor today and start your journey.`;
    }

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>

    const categoryDescriptions = {
      information_technology: description,
      recruitment_and_hr: "Are you looking to grow and excel in your career in the HR industry? Sign up with Career Navig8r and find an HR mentor based on your job role now!",
      accountancy_banking_and_finance: "Are you looking for the best mentors to help you advance your finance career? Sign up with Career Navig8r for job role-specific finance mentoring now!",

      environment_and_agriculture: 'Looking to improve your job skills in the environment and agriculture industry? Sign up for Career Navig8r’s agriculture mentorship programme today!',
      engineering_and_manufacturing: 'Want to enhance your engineering and manufacturing skills and grow in the industry? Find an engineering mentor or manufacturing mentor at Career Navig8r now!',
      marketing_advertising_and_pr: 'Want to excel at your career in marketing, advertising, or PR? Find a marketing mentor, advertising mentor, or PR mentor online at Career Navig8r now!',
      business_consulting_and_management: 'Are you an entrepreneur looking for business coaching and mentoring to enhance your career? Sign up with Career Navig8r to find a business mentor now!',
      law: 'Are you a lawyer looking to grow your career and excel in your industry? Find a law mentor based on your specific job role at Career Navig8r now!',
      teacher_training_and_education: 'Are you a teacher looking to enhance your teaching skills? Find a teaching mentor for your specific job role at Career Navig8r now!',
      retail: 'Need retail career guidance to get better at your retail job? Sign up with Career Navig8r to start looking for your retail mentor today.',
      sales: 'Want sales career advice from someone with experience in the industry? Sign up with Career Navig8r to find your sales mentor today.',
      hospitality_and_events_management: 'Need hospitality and events management training to advance in this industry? Sign up with Career Navig8r to find your hospitality and events management mentor.',
      social_care: 'Want social care career guidance to improve your job skills? Sign up with Career Navig8r to find your ideal social care mentor online.',
      science_and_pharmaceuticals: 'Need science and pharmaceuticals training to excel at your job in this industry? Sign up with Career Navig8r to find your science and pharmaceuticals mentor.',
      creative_arts_and_design: 'Need creative arts and design training to improve your job skills? Sign up with Career Navig8r to find your ideal creative arts and design mentor online.',

      transport_and_logistics: 'Want to get better at your job in the transport and logistics industry? Sign up with Career Navig8r to find your transport and logistics mentor today.',
      charity_and_voluntary_work: 'Want to build a career in the charity and voluntary work industry? Sign up with Career Navig8r to find your ideal charity and volunteer work mentor.',
      law_enforcement_and_security: 'Looking to improve your skills as a law enforcement officer? Sign up for Career Navig8r’s law enforcement and security mentoring programme today.',
      public_services_and_administration: 'Want to grow your expertise in the public services and admin industry? Sign up with Career Navig8r to find your public services and administration mentor.',
      leisure_sport_and_tourism: 'Need leisure, sports and tourism career advice from an expert? Sign up for Career Navig8r’s leisure, sports and tourism mentoring programme.',
      media_and_internet: 'Want to excel at your job in the media and internet industry? Sign up with Career Navig8r to find your ideal media and internet mentor today.',
      energy_and_utilities: 'Looking to excel at your job in the energy and utilities industry? Sign up for Career Navig8r’s energy and utilities mentoring programme today.',
    };

    const roleDescriptions = {
      'it-manager-informationtechnology': "Looking to enhance your managerial skills in the IT industry? Find your ideal IT manager mentor by signing up for Career Navig8r’s mentoring course today.",
      'marketing-manager-marketingandcommunications': "Looking to advance your career as a marketing manager? Find your marketing manager mentor by signing up for Career Navig8r’s online course.",
      'compliance-manager-consultingandstrategy': "Want to learn how to be a compliance manager and grow in the industry? Sign up with Career Navig8r to pick your ideal compliance manager mentor today.",
      'data-scientist-informationtechnology': "Are you looking for career guidance as a current or aspiring data scientist? Sign up with Career Navig8r for effective data scientist mentoring today.",
      'facilities-manager-administrationandofficesupport': "Looking to grow in your career as a facilities manager? Sign up with Career Navig8r to find your ideal facilities manager mentor online.",
      'customer-service-representative-callcenterandcustomerservice': "Want the best tips on how to be a customer service representative? To find your ideal customer service mentor online, sign up with Career Navig8r today.",
      'accounting-clerk-accounting': "Are you looking to improve your job skills as an accounting clerk? Find your ideal accounting clerk mentor by signing up with Career Navig8r today.",
      'staff-accountant-accounting': "Are you a staff accountant looking for career guidance? To find your ideal staff accountant mentor, sign up for Career Navig8r’s mentorship course today.",
      'it-director-informationtechnology': "Looking to enhance your job performance and growth as an IT director? Sign up with Career Navig8r to find your ideal IT director mentor today.",
      'senior-accountant-accounting': "Are you looking to advance your career as a senior accountant? Sign up with Career Navig8r to find your ideal senior accountant mentor today.",
      'accounts-manager-accounting': "Are you looking to improve your career skills as an accounts manager? Find the best accounts manager mentor by signing up with Career Navig8r today.",
      'chief-financial-officer-bankingandfinancialservices': "Want to improve your job skills and excel in your career as a CFO? Sign up with Career Navig8r and find your chief financial officer mentor today.",
      'it-support-specialist-informationtechnology': "Need the expert guidance of an IT support specialist mentor to advance in your career? Sign up with Career Navig8r to find your job-specific career mentor now.",
      'public-relations-manager-marketingandcommunications': "Want expert tips on how to be a PR manager? Sign up with Career Navig8r to find your PR manager mentor today.",
      'registered-nurse-healthcareandmedical': "Want to know how to get a registered nurse role and excel in this career? Sign up with Career Navig8r to find your registered nurse mentor today.",
      'hr-manager-humanresourcesandrecruitment': "Want to learn how to be a great HR manager and advance your career? Find your ideal HR manager mentor by signing up with Career Navig8r today.",
      'sales-manager-sales': "Are you a current or aspiring sales manager looking to grow and excel in your job role? Sign up with Career Navig8r to find your sales manager mentor today.",
      'operations-manager-executive': "Want effective strategies on how to be an operations manager? Find your operations manager mentor by signing up with Career Navig8r today.",
      'plant-manager-manufacturingtransportandlogistics': "Need expert guidance on how to be a plant manager? Sign up for Career Navig8r’s training course to find your ideal plant manager mentor today.",
      'hr-assistant-humanresourcesandrecruitment': "Need human resources training to be a better HR assistant? Sign up for Career Navig8r’s HR course and find your ideal human resources mentor today.",
      'paralegal-legal': "Wondering how to work in paralegal or how to get a compliance role? Sign up for Career Navig8r’s course to find your paralegal mentor today.",
      'engineering-manager-engineering': "Need expert tips on how to be an engineering manager? Sign up for Career Navig8r’s course and choose your ideal engineering manager mentor today.",
      'sales-representative-sales': 'Need sales rep training to excel in your job role? Sign up for Career Navig8r’s sales course to find your ideal sales rep mentor today.',
      'creative-director-advertisingartsandmedia': 'Need expert advice on how to be a creative director? To find your creative director mentor and improve your job skills, sign up with Career Navig8r today.',
      'school-principal-educationandtraining': 'Want expert advice on how to become a school principal? Sign up for Career Navig8r’s course to find your school principal mentor today.',
      'office-manager-administrationandofficesupport': 'Want to improve your job skills as an office manager? Find your office manager mentor by signing up for Career Navig8r’s training course today.',
      'system-administrator-informationtechnology': 'Are you a new or experienced system administrator looking to enhance your skills? Sign up with Career Navig8r to find your systems administrator mentor now.',
      'operations-assistant-administrationandofficesupport': 'Want to learn how to work as an operations assistant? To find an operations assistant mentor and improve your skills, sign up for Career Navig8r’s course.',
      'production-worker-manufacturingtransportandlogistics': 'Want to learn how to be a production worker? Sign up for Career Navig8r’s training course to find your ideal production worker mentor today.',
      'data-analyst-informationtechnology': 'Want to learn how to work as a data analyst? Sign up for Career Navig8r’s training course to find your data analyst mentor today.',
      'junior-engineer-engineering': 'Want expert tips and advice on how to work in engineering? Sign up for Career Navig8r’s engineer training course to find your engineering mentor today.',
      'marketing-coordinator-marketingandcommunications': 'Want to learn how to excel in your job as a marketing coordinator? Sign up for Career Navig8r’s course to find your marketing coordinator mentor today.',

      'engineer-engineering': 'Want expert advice on how to be an engineer? Sign up for Career Navig8r’s engineering training course to find your engineer mentor today.',
      'regional-sales-manager-sales': 'Learn more about working as a regional sales manager in this guide. Sign up with Career Navig8r to find your regional sales manager mentor today.',
      'research-and-development-manager': 'Want expert advice on how to be a research and development manager? Sign up with Career Navig8r to find your r&d manager mentor today.',
      'medical-assistant-healthcareandmedical': 'Want expert advice on how to be a medical assistant? Sign up for Career Navig8r’s training course to find your ideal medical assistant mentor today.',
      'research-assistant-administrationandofficesupport': 'Want to learn how to be a research assistant? Enrol in Career Navig8r’s training course and find your research assistant mentor today.',

      'chief-information-officer': 'Want to improve your job skills as a chief information officer? Sign up with Career Navig8r to find your chief information officer mentor today.',
      'senior-marketing-manager': 'Want expert advice on how to become a senior marketing manager? Sign up with Career Navig8r to work with your ideal senior marketing manager mentor.',
      'chief-sales-officer': 'Want effective CSO training to help you excel in your job? Sign up for Career Navig8r’s CSO mentorship and choose your chief sales officer mentor today.',
      'chief-human-resources-officer': 'Want to reap the benefits of expert CHRO training? Sign up with Career Navig8r to find a chief human resources officer mentor today.',
      'marketing-director-marketingandcommunications': 'Want to improve your skills through marketing director career guidance? Sign up with Career Navig8r to find your marketing director mentor.',
      'chief-marketing-officer-marketingandcommunications': 'Want to improve your job skills as a chief marketing officer? Sign up with Career Navig8r to find a chief marketing officer mentor today.',
      'sales-director-sales': 'Want to learn how to excel in your job as a sales director? Sign up for Career Navig8r’s coaching to find your sales director mentor today.',
      'hr-generalist-humanresourcesandrecruitment': 'Want to excel at your job as a HR generalist? Sign up for Career Navig8r’s mentorship to find your HR generalist mentor today.',
      'hr-director-humanresourcesandrecruitment': 'Want to learn how to be a HR director and climb the corporate ladder? Sign up with Career Navig8r to find your HR director mentor today.',
      'public-relations-director-marketingandcommunications': 'Want to learn how to be a PR director successfully? Sign up with Career Navig8r’s training programme to find your PR director mentor today.',
      'public-relations-marketingandcommunications': 'Do you want to excel in your job as a PR specialist? Sign up with Career Navig8r to choose your ideal PR specialist mentor who’ll guide you.',
      'compliance-analyst-consultingandstrategy': 'Want compliance analyst training from an expert to excel at your job? Sign up with Career Navig8r to find your compliance analyst mentor online today.',

      'vp-of-engineering-informationtechnology': 'Want to excel in your career as a VP of engineering? Sign up with Career Navig8r to find your ideal engineering vice president mentor today.',
      'business-intelligence-analyst-consultingandstrategy': 'Looking to improve your skills as a business intelligence analyst? Sign up with Career Navig8r to find your ideal business intelligence analyst mentor today.',
      'chief-data-officer-informationtechnology': 'Want to excel at your job as a chief data officer through proper CDO training? Sign up for Career Navig8r’s chief data officer mentoring programme today.',
    };

    let metaDescription = description;

    if (urlQueryParams?.pub_category) {
      metaDescription = categoryDescriptions[urlQueryParams.pub_category] || description;
    } else if (location?.pathname) {
      const matchingRole = Object.keys(roleDescriptions).find(role => location.pathname.includes(role));
      if (matchingRole) {
        metaDescription = roleDescriptions[matchingRole];
      }
    }

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={metaDescription}
        title={title}
        schema={schema}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
        />
        <div className={css.container}>
          <MainPanel
            urlQueryParams={validQueryParams}
            currentUser={currentUser}
            listings={listings}
            jobRoleOption={jobRoleOption}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            searchParamsAreInSync={searchParamsAreInSync}
            onActivateListing={onActivateListing}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={this.onOpenMobileModal}
            onCloseModal={this.onCloseMobileModal}
            onMapIconClick={onMapIconClick}
            pagination={pagination}
            searchParamsForPagination={parse(location.search)}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            history={history}
            intl={intl}
            sendEnquiryInProgress={sendEnquiryInProgress}
            sendEnquirySuccess={sendEnquirySuccess}
            sendEnquiryError={sendEnquiryError}
            onSendEnquiry={onSendEnquiry}
            addToFavorites={addToFavorites}
            removeFromFavorites={removeFromFavorites}
            location={location}
          />
          <ModalInMobile
            className={css.mapPanel}
            id="SearchPage.map"
            isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
            onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.mapWrapper}>
              {shouldShowSearchMap ? (
                <SearchMap
                  reusableContainerClassName={css.map}
                  activeListingId={activeListingId}
                  bounds={bounds}
                  center={origin}
                  isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                  location={location}
                  listings={mapListings || []}
                  onMapMoveEnd={this.onMapMoveEnd}
                  onCloseAsModal={() => {
                    onManageDisableScrolling('SearchPage.map', false);
                  }}
                  messages={intl.messages}
                />
              ) : null}
            </div>
          </ModalInMobile>
        </div>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
    sendEnquiryInProgress,
    sendEnquirySuccess,
    sendEnquiryError,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    currentUser,
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    sendEnquiryInProgress,
    sendEnquirySuccess,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onSendEnquiry: (email, mentoringType) => dispatch(sendEnquiry(email, mentoringType)),
  addToFavorites: listingId => dispatch(addListingToFavorites(listingId)),
  removeFromFavorites: listingId => dispatch(removeListingFromFavorites(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
